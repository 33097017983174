@use '../../styles/variables';

.noPointerEvents {
  pointer-events: none;
}

.bottomWrapper {
  @apply relative bottom-0 z-10 flex w-full flex-col justify-center;

  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    var(--primary) 0%,
    var(--primary-light) 15%,
    var(--primary-light) 85%,
    var(--primary) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(variables.$primary, 0) 0%,
    var(--primary) 100%
  );

  @media screen and (max-width: 420px) {
    /* height: 182px; */
    border-image-source: linear-gradient(
      to left,
      var(--primary) 4.7%,
      var(--primary-light) 0%,
      var(--primary-light) 95.3%,
      var(--primary) 95.3%
    );
  }
}

.bgPlanet {
  @apply absolute;
  width: 100%;
  height: 400px;
  left: 0;
  top: 270px;

  background: rgba(variables.$primary-dark, 1);
  border-radius: 50%;
  z-index: 1;
  box-shadow:
    0 2px 62px 9px rgba(variables.$primary-shade, 0.6),
    0 0 28px 4px rgb(187, 110, 255),
    inset 12px 12px 22px 0 rgba(221, 160, 255, 0.4),
    inset 3px -2px 16px 0 rgba(variables.$primary-shade, 1),
    inset 0 0 7px 0 rgba(variables.$secondary, 0.5),
    inset 0 50px 56px 0 rgba(variables.$primary-shade, 0.8);

  @screen xs {
    width: 740px;
    height: 740px;
    left: calc(50% - 370px);
  }
}
