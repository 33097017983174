
.outerSpan::before {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border: 3px solid rgb(134 239 172);
    border-right: none;
    border-bottom: none;
    width: 50%;
    height: calc(100% - 2px);
    transition: width 1s ease-out 2s, height 1s ease-out 1s;
    border-top-left-radius: 6px;
}

.outerSpanExpanded::before {
    height: 0px;
    width: 0px;
}

.outerSpan::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border: 3px solid rgb(134 239 172);
    border-left: none;
    border-bottom: none;
    width: 50%;
    height: calc(100% - 2px);
    transition: width 1s ease-out 2s, height 1s ease-out 1s;
    border-top-right-radius: 6px;
}

.outerSpanExpanded::after{
    height: 0px;
    width: 0px;
}


//innerspan

.innerSpan::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0px;
    height: 1px;
    width: 50%;
    transition: width 1s ease-out;
    border-bottom: 3px solid rgb(134 239 172);
    border-bottom-right-radius: 6px;
}

.outerSpanExpanded .innerSpan::before {
    height: 0px;
    width: 0px;
}

.innerSpan::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 1px;
    width: 50%;
    transition: width 1s ease-out;
    border-bottom: 3px solid rgb(134 239 172);
    border-bottom-left-radius: 6px;
}

.outerSpanExpanded .innerSpan::after {
    height: 0px;
    width: 0px;
}

// from https://www.sliderrevolution.com/resources/css-border-animation/