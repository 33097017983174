$size: 18px;

.badge {
  width: $size;
  height: $size;
  @apply absolute z-10 flex items-center justify-center rounded-full bg-secondary text-sm font-bold;

  .number {
    @apply z-20 text-center text-primary;
  }

  &.dynamic {
    @apply w-auto px-1 text-xs;
    min-width: $size;
  }
}

.sideMenuNotification {
  width: fit-content;
  @apply absolute left-4 top-0 z-10
    flex content-center items-center justify-items-center rounded-full;
}

.unSelected {
  background-color: #fff;
}

.promo {
  width: 31px;
  height: 31px;
  line-height: 31px;
  @apply relative;
  .number {
    @apply px-2;
    width: auto;
    min-width: 31px;
    margin: 5px 0 7px;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.18px;
    text-align: center;
    font-size: 22px;
  }
}

.animation {
  @apply absolute left-0 top-0 z-10 inline-flex h-full w-full animate-notification rounded-full bg-secondary opacity-75;
}

.value {
  @apply relative z-20;
}
