.button {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 900;
  background: #ffffff;
  color: var(--primary);
  height: 3rem;
  max-width: 100%;
  width: 100%;
  padding: 0;
  box-shadow: 1px 1px 2px 0px #0000004D inset;
}

.selected {
  // background-image: linear-gradient(0deg, rgba(102, 36, 130, 0.2) 0, rgba(102, 36, 130, 0.3) 100%);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--secondary);
}

.button > svg {
  max-width: 90px;
  max-height: 30px;
}

