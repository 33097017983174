.section {
  @apply flex w-full flex-col items-center uppercase;
  &-header {
    @apply mb-3.5 text-center text-3xl font-semibold text-white;
    letter-spacing: 0.18px;
  }
}

.moreInfoDescription {
  @apply uppercase;
  opacity: 0.59;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.15px;
  text-align: center;
  color: #fff;
}
