$primary: #662482;
$primary-mid: #8e24bb;
$primary-light: #7a3896;
$primary-dark: #5f197f;
$primary-shade: #2f0044;
$primary-shadow: #4b006b;
$secondary: #fed906;
$secondary-dark: #f8ad0b;
$success: #3d943d;
$error: #ff2929;
$test: rgba(102, 36, 130, 0.2);
$primary-lighter: #794490;
