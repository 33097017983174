$actionWidth: 16px;

.item {
  @apply mb-4 w-full bg-black text-white;
  &.transparent {
    @apply bg-black/10;
  }
  border-radius: 0.75rem;
}

.Unset {
  background: #fff;
  color: var(--primary);
  hr {
    border-color: rgb(var(--primary) / var(--tw-border-opacity));
  }
}

.headline {
  @apply grid w-full cursor-pointer grid-cols-[16px_1fr_16px] items-center p-4 font-bold;
}

.laqHeadline {
  @apply p-0 py-4 lg:px-4;
}

.headlineTitle {
  @apply flex-1 text-center uppercase;
}

.panel {
  @apply block w-full;
  &.transparent {
    @apply bg-transparent;
  }
}

.separator {
  @apply m-auto;
  max-width: 100px;
}

.panelContent {
  @apply p-4 text-center;
}

.headlineAction {
  transition: all 0.3s ease;
}

.headlineActionExpanded {
  transform: rotateX(-180deg);
}
