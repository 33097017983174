@keyframes fade {
  0% {
    background-color: var(--primary-shade);
  }

  100% {
    background: black;
  }
}

.loader {
  @apply fixed bottom-0 left-0 right-0 top-0 z-10 flex h-screen w-screen items-center justify-center;
}

.inGame {
  height: 75px;
  position: relative;
  z-index: 50;
  background-color: var(--primary-shade);
}

.iframe {
  @apply w-screen;
  height: calc(100% - 75px);
}
