$size: 8px;
$color: rgba(255, 255, 255, 0.3);
$color-active: rgba(255, 255, 255, 0.8);
$color-progress-active: var(--secondary);

.wrapper {
  @apply mb-10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dot {
  position: relative;
  width: $size;
  height: $size;
  border-radius: $size;
  background-color: $color;
  margin-left: 0.5rem;
  transition: all 0.4s ease;
}

.active {
  background-color: $color-active;
}

.progress.dot:first-child:before {
  content: none;
}

.progress.dot:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: $color;
  top: 3px;
  left: -100%;
  z-index: -1;
}

.progress.dot.active.dot:before {
  border-color: $color-progress-active;
  background: $color-progress-active;
}

.progress.active {
  background-color: $color-progress-active;
}
