.button {
  @apply flex h-7 w-7 select-none items-center justify-center rounded-full transition-opacity;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;

  svg {
    opacity: 0.75;
  }

  &:first-child {
    margin-right: 5px;
  }
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.singleWrapper {
  position: relative;
  z-index: 10;
}

.overSetMaxWidth {
  max-width: 240px;
  .navigationButton {
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
  }
}

.gallery {
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  z-index: 1000;
  position: relative;
  width: 100%;
}

.gallery::-webkit-scrollbar {
  inline-size: 0 !important;
  display: none;
}

.wrapper {
  //grid-column: content;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.wrapper::after {
  content: '';
  align-self: stretch;
  padding-inline-end: max(var(--space), (100vw - 60ch) / 2 - 1rem);
}

.item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  inline-size: 100%;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column;
}

.gallery.alt-sizes .item.lg {
  max-inline-size: 35rem;
}

.gallery.alt-sizes .item.sm {
  max-inline-size: 15rem;
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigationButton {
  @apply shadow-purpleShadow;
  position: absolute;
  z-index: 9999;

  &.prev,
  &.next {
    top: 50%;
    transform: translateY(-15px);
    transition: opacity 0.2s ease;
  }

  &.prev {
    left: -15px;
    background: linear-gradient(to bottom, var(--primary), var(--primary-dark));
  }
  &.next {
    right: -15px;
    background: linear-gradient(to bottom, var(--primary), var(--primary-dark));
  }
  &.muted {
    opacity: 0;
  }
}

.jackpotSliderButton{
  position: absolute;
  z-index: 9999;
  background: transparent;
  &.prev,
  &.next {
    top: 50%;
    transform: translateY(-15px);
    transition: opacity 0.2s ease;
  }

  &.prev {
    left: -15px;
  }
  &.next {
    right: -15px;
  }
  &.muted {
    opacity: 0;
  }
}
