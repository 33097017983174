.root {
  display: inline-block;
  position: relative;
  width: 100%;

  &.fixed {
    position: absolute;
    inset: 0;
    z-index: 200;
    width: unset;
    height: unset;

    &:after {
      border-radius: 0;
      inset: 0;
    }
  }

  &.width-25 {
    width: 25%;
  }

  &.width-50 {
    width: 50%;
  }
  &.width-75 {
    width: 75%;
  }
  &:after {
    content: '';
    position: absolute;
    inset: 5px 0;
    background: linear-gradient(
      45deg,
      var(--primary-light),
      var(--primary),
      var(--primary-light)
    );
    display: block;
    border-radius: 0.5rem;
    padding: 2px 0;
    animation: skeleton 1s linear;
    animation-iteration-count: infinite;
    background-position: -100%;
    background-size: 200%;
  }
}

@keyframes skeleton {
  from {
    background-position: -100%;
  }
  to {
    background-position: 100%;
  }
}
